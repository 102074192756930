import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Layout, SEO } from 'components';
import { rhythm } from 'utils/typography';

export const BaseSection = styled.section`
  background-color: #fff;
  margin: 0 auto;
  max-width: 60rem;
  padding: ${rhythm(2)} ${rhythm(1)};
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
      <BaseSection>
        <h1>NOT FOUND</h1>
        <p>
          You just hit a route that doesn&#39;t exist...😭. We suggest returning
          to our <Link to="/">home page</Link>.
        </p>
      </BaseSection>
    </main>
  </Layout>
);

export default NotFoundPage;
